@font-face {
	font-family: DSEG7Classic;
	src: url("/public/DSEG7Classic.ttf");
}

body {
	margin: 0;
	padding: 0;
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Fbackground.png") no-repeat;
	background-size: 100%;
	overflow: hidden;
}

.container { 
	width: 1920px;   
	height: 1080px;    

	transform-origin: 0 0;
	display: flex;
	justify-content: center;
	align-items: center;
	transform-style: preserve-3d;
	perspective: 800px;
	position: relative;  
}

.mask {
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 99;
}

.exitBox {
	width: 955px;
	height: 447px;
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2FexitBox.png") no-repeat;
	background-size: 100%;
	position: absolute;
	top: 30%;
	left: 0;
	right: 0;
	margin: auto;
	z-index: 100;
	display: flex;
	justify-content: space-around;
	align-items: flex-end;
	padding:0 30px 100px;
}

.exitBox .exitBtn {
	width: 290px;
	height: 58px;
	cursor: pointer;
} 
.exitBox .cancel {
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Fcancel.png") no-repeat;
	background-size: 100%;
}

.exitBox .confirm {
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Fconfirm.png") no-repeat;
	background-size: 100%;
}

.exit {
	height: 32px;
	font-size: 18px;
	color: #FFFFFF;
	line-height: 32px;
	display: flex;
	position: absolute;
	right: 60px;
	top: 28px;
	cursor: pointer;
}

.exit .exitIcon {
	width: 32px;
	height: 32px;
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Fexit.png") no-repeat;
	background-size: 100%;
	margin-right: 10px;
}

.container .title {
	width: 550px;
	height: 67px;
	text-align: center;
	position: absolute;
	top: 109px;
	left: 0;
	right: 0;
	margin: auto;
	font-size: 48px;
	color: #FFFFFF;
	line-height: 67px;
	letter-spacing: 12px;
	text-shadow: 0px 4px 10px #72EDFF;
}

.container .subText {
	color: #FEBA01;
	font-size: 22px;
	width: 178px;
	position: absolute;
}

.container .leftTitle { 
	left: 26px;
	top: 250px;
	transform: skewY(10deg);
	text-align: right; 
} 
.container .leftContent { 
	position: absolute;
	left: 0px;
	top: 280px;  
	transform: rotateY(29deg) translateZ(-400px) translateX(-10px) translateY(-97px); 
	/* transform: skewY(8deg) scale(0.7) translateX(-100px);  */
}           

.container .rightTitle {
	right: 8px;
	top: 250px;
	text-align: left;
	transform: skewY(-10deg);
}
.container .rightContent { 
	width: 500px;
	height: 560px;
	position: absolute;
	right: 50px;
	top: 300px;   
	transform: rotateY(319deg) skewY(-8deg) translateZ(-400px) translateX(-350px) translateY(-97px); 
}

.container .center { 
	width: 957px;
	height: 700px;
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Fcenter.png") no-repeat;
	background-size: 100%;
	position: relative; 
	opacity: 0;
	animation: initCenter .8s .5s forwards;
}

.container .other {
	width: 859px;
	height: 700px;
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2FotherPanel.png") no-repeat;
	background-size: 100%;
	position: relative; 
	opacity: 0;
	animation: initCenter .8s .5s forwards; 
}

.container .other .titleRow {
	height: 48px;
	line-height: 48px;
	width: 779px; 
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Ftitle.png") no-repeat;
	background-size: 100%;
	font-size: 24px;
	color: #FFFFFF;;
	padding-left: 20px;
	margin: 40px 0 0 40px;
	display: flex;
	justify-content: space-between;
}

@keyframes initCenter{
	0%{ 
		transform: scale(0);
	}
	80%{
		transform: scale(1.1);
	}
	100% {
		opacity: 1;
	}
}

.container .other .columnChart {
	height: 260px;
}

.container .other .lineTwoMonthChart {
	height: 400px;
}

.container .other .earth {
	height: 336px;
	width: 600px;
	margin: auto;
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Fpartner.png") no-repeat;
	background-size: 100%; 
	margin-bottom: -30px;
}

.container .other .earth .earthTxt {
	width: 90%;
	text-align: center; 
	font-size: 28px;
	color: #FFFFFF;
	line-height: 40px;
	text-shadow: 0px 4px 10px #72EDFF;
	height: 150px;
	line-height: 200px; 
}

.container .other .earth .earthNum {
	width: 90%;
	font-family: "DSEG7Classic";
	text-align: center; 
	font-size: 72px;
	color: #FFFFFF;
	line-height: 52px;
	text-shadow: 0px 4px 10px #72EDFF;
}

.container .other .earth .earthNum .ren {
	font-size: 28px;
	color: #FFFFFF;
	line-height: 40px;
	text-shadow: 0px 4px 10px #72EDFF;
}

.container .other .today {
	width: 220px;
	height: 33px;
	font-size: 24px;
	font-weight: 500;
	color: #FFFFFF;
	margin-left: 43px;
	line-height: 33px;
	white-space: nowrap;
	letter-spacing: 6px;
	text-shadow: 0px 4px 10px #72EDFF;
}

.container .other .flex_evenly { 
	display: flex;
	justify-content: space-evenly; 
	align-items: center; 
	height: 260px;
}

.container .other .flex_evenly .num {
	width: 146px;
	height: 200px;
	color: #FFFFFF;
	font-size: 24px;
	text-shadow: 0px 4px 10px #72EDFF;
	text-align: center;
}

.container .other .flex_evenly .num .box {
	height: 62px;
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Fbox.png") no-repeat;
	background-size: 100%; 
	line-height: 62px;
}

.container .other .flex_evenly .num .bottom {
	height: 135px;
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Fbottom.png") no-repeat;
	background-size: 100%;
	margin-top: 10px; 
	line-height: 135px;
}

.container .other .flex_evenly .num .up {
	width: 140px;
	height: 36px;
	font-family: "DSEG7Classic";
	font-size: 36px; 
	margin-bottom: -30px;
}

.container .other .flex_evenly .num .down {
	width: 140px;
	height: 90px;
	font-size: 14px;
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Fpv.png") no-repeat;
	background-size: 100%; 
	line-height: 126px;
	text-align: center;
}

.container .other .between {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding: 30px 50px 10px;
	text-shadow: 0px 4px 10px #72EDFF;
	font-size: 48px;
	color: #FFFFFF;
	text-align: center;
	font-family: "DSEG7Classic";
}

.container .other .between .green {
	width: 160px;
	height: 160px; 
	font-size: 40px;
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Fgreen.png") no-repeat;
	background-size: 100%;
}

.container .other .between .green .greenNum ,.redNum { 
	text-align: left;
	padding-left: 29px;
}

.container .other .between .green .itemboxTop,.container .other .between .blue .itemboxTop,.container .other .between .red .itemboxTop { 
	font-size: 16px;
	line-height: 30px; 
} 

.container .other .between .blue {
	width: 380px;
	height: 271px;  
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Fblue.png") no-repeat;
	background-size: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.container .other .between .blue .blueNum {
	font-size: 58px; 
}
 
.container .other .between .red {
	width: 160px;
	height: 160px;
	font-size: 40px;
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Fred.png") no-repeat;
	background-size: 100%; 
}
 
.container .other .between .itembox {
	width: 240px;
	height: 85px;
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Fitembox.png") no-repeat;
	background-size: 100%;
	position: relative;
}

.container .other .between .itembox .textItem,.container .other .between .redcenter .textItem{
	position: absolute;
	font-size: 18px;
	bottom: -30px;
	left: 0;
	right: 0;
	margin: auto;
}

.container .other .between .redcenter {
	position: relative;
	width: 240px;
	height: 85px;
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Fredcenter.png") no-repeat;
	background-size: 100%;
}

.container .other .flex_between {
	display: flex;
	justify-content: space-between;
}
 

.container .other .flex_between .barChart {
	flex: 1; 
}

.container .other .flex_between .barChart .userItem {
	display: flex;
	justify-content: space-around;  
	align-items: center; 
	color: #FFFFFF;
	line-height: 25px;
	text-shadow: 0px 4px 10px #72EDFF;
	padding: 30px 0 10px 30px;
}

.container .other .flex_between .barChart .userItem .icon {
	width: 82px;
	height: 78px;
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Ficon.png") no-repeat;
	background-size: 100%;
}

.container .other .flex_between .barChart .userItem .textNum {
	font-size: 18px; 
}

.container .other .flex_between .barChart .userItem .humanNum {
	font-family: "DSEG7Classic";
	font-size: 32px;  
	width: 110px;
	text-align: left;
}

.container .other .flex_between .radioChart {
	flex: 1;   
}

.container .other .flex_between .userData {
	flex: 1;  
	display: flex;    
	align-items: center;
}

.container .other .flex_between .userData .circle {
	width: 220px;
	height: 220px;
	font-family: "DSEG7Classic";
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Fcircle.png") no-repeat;
	background-size: 100%;
	margin: 30px 0 0 30px; 
	color: #FFFFFF;
	line-height: 22px;
	text-shadow: 0px 4px 10px #72EDFF;
	font-size: 20px;
	text-align: center;
	line-height: 220px;
}

.container .other .flex_between .userData .lengend {
	height: 160px; 
	margin: 30px 0 0 30px;
	width: 110px; 
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	color: #FFFFFF;
	font-size: 16px;
}
 
.homeHelper::before {
	content: '';
	display: inline-block;
	margin-right: 10px;
	width: 20px;
	height: 10px;
	background-color: #0348BC;
}

.farmerHelper::before {
	content: '';
	display: inline-block;
	margin-right: 10px;
	width: 20px;
	height: 10px;
	background-color: #FFD04F;
} 

.container .center .top {
	position: absolute;
	color: white;
	letter-spacing: 4px;
}
.container .center .time { 
	left: 115px;
}
.container .center .nextTime { 
	right: 115px;
} 
.container .center .day {
	left: 415px;
}
.container .center .totalCount {
	font-family: "DSEG7Classic";
	width: 100%;
	height: 74px;
	font-size: 72px; 
	font-weight: normal;
	color: #FFFFFF;
	line-height: 65px;
	text-align: center;
	letter-spacing: 40px;
	text-shadow: 0px 4px 10px #72EDFF;
	top: 53px;
	left: 0;
	right: 0;
	margin: auto; 
}

.container .center .totalCount .qian {
	font-size: 40px;
}

.container .center .middle {
	position: absolute;
	width: 766px;
	top: 229px;
	left: 90px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 54px; 
}

.container .center .middle .square {
	width: 170px;
	height: 50px; 
	display: flex;
	justify-content: space-between;
}

.container .center .middle .square .fourIcon {
	width: 48px;
	height: 48px;
}
.container .center .middle .square .orderSuccess { 
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Fsuccess.png") no-repeat;
	background-size: 100%;
}
.container .center .middle .square .lastDay { 
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2FlastDay.png") no-repeat;
	background-size: 100%;
}
.container .center .middle .square .lastWeek { 
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2FlastWeek.png") no-repeat;
	background-size: 100%;
}
.container .center .middle .square .lastMonth { 
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2FlastMonth.png") no-repeat;
	background-size: 100%;
}
.container .center .middle .square .rightSquare {
	width: 100px;
	height: 50px;
}
.container .center .middle .square .rightSquare .digtal { 
	width: 100px;
	height: 30px;
	font-size: 32px;
	font-family: "DSEG7Classic"; 
	color: #FFFFFF;
	line-height: 26px;
}
.container .center .middle .square .rightSquare .bottomText {
	width: 100%;
	height: 20px;
	line-height: 20px;
	font-size: 14px; 
	font-weight: 500;
	color: #3EB7F8;
	line-height: 20px; 
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.container .center .middle .square .rightSquare .bottomText .arrow {
	width: 14px;
	height: 16px;
	display: inline-block; 
}
.downArrow {
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2FdownArrow.png") no-repeat;
	background-size: 100%;
	animation: downMove 0.8s linear infinite forwards;
}
@keyframes downMove {
	0% {
		opacity: 0.5;
		transform: translateY(-4px);
	}
	100% {
		opacity: 1;
		transform: translateY(4px);
	}
}
.upArrow {
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2FupArrow.png") no-repeat;
	background-size: 100%;
	animation: upMove 0.8s linear infinite forwards;
}
@keyframes upMove {
	0% {
		opacity: 0.5;
		transform: translateY(3px);
	}
	100% { 
		opacity: 1;
		transform: translateY(-3px);
	}
}
.container .center .bottom {
	width: 808px;
	height: 347px;
	position: absolute;
	left: 73px;
	top: 303px;
}
.container .center .bottom .tabs {
	width: 808px;
	height: 45px; 
	letter-spacing: 2px;
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Ftab1.png") no-repeat;
	background-size: 100%; 
	color: white;
	display: flex;
	text-align: center;
}
.container .center .bottom .tabs .t1 {
	height: 40px;
	line-height: 50px;
	width: 200px; 
	font-size: 20px;
	text-shadow: 0px 4px 10px #72EDFF; 
}
.container .center .bottom .tabs .t2 {
	width: 150px;
	font-size: 19px;
	line-height: 50px;
	color: #AAAAAA; 
}
.t1:hover ,.t2:hover,.s1:hover ,.s2:hover { 
	cursor: pointer;
}
.switch {
	width: 145px;
	height: 40px; 
	position: absolute;
	right: 0;
	top: 0;
}
.s1 {
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Fs1.png") no-repeat;
	background-size: 100%; 
} 
.s2 {
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Fs2.png") no-repeat;
	background-size: 100%; 
} 
.container .center .bottom .tabs_select { 
	position: relative;
	width: 808px;
	height: 45px; 
	letter-spacing: 2px; 
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Ftab2.png") no-repeat;
	background-size: 100%; 
	color: white;
	display: flex;
	text-align: center;
}
.container .center .bottom .tabs_select .t2 {
	height: 40px;
	line-height: 50px;
	width: 200px; 
	font-size: 20px;
	text-shadow: 0px 4px 10px #72EDFF; 
}
.container .center .bottom .tabs_select .t1 {
	width: 180px;
	font-size: 19px;
	line-height: 50px;
	color: #AAAAAA; 
}
.container .center .bottom .chartLine {
	/* border: 1px solid red; */
	box-sizing: border-box;
	padding: 20px 0 50px;
	height: 100%;
}
.container .swiper {
	height: 212px;
	width: 92%;
	position: fixed;
	bottom: 5px; 
	display: flex;
	justify-content: space-between;
}
.container .swiper .nav {
	width: 200px;
	height: 103px; 
}
.container .swiper .nav:hover {
	cursor: pointer;
}
.container .swiper .item {
	width: 316px;
	height: 165px; 
	transition: .5s;
	cursor: pointer;
}  
.container .swiper .item:nth-child(2) {
	transform: skewY(4deg); 
	
} 
.container .swiper .item:nth-child(3) {
	transform: translateY(20px) skewY(1deg); 
	
} 
.container .swiper .item:nth-child(4) {
	transform: translateY(24px) skewY(-1deg);  
} 
/* 平台商户商品趋势 */
.ptshspqs {
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Ftabbar/ptshspqs.png") no-repeat;
	background-size: 100%;
}
/* 平台访问量 */
.ptfwl {
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Ftabbar/ptfwl.png") no-repeat;
	background-size: 100%;
}
/* 农特品销量排行 */
.ntpxlph {
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Ftabbar/ntpxlph.png") no-repeat;
	background-size: 100%;
}
/* 当前实时交易量 */
.dqssjyl {
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Ftabbar%2Fdqssjy.png") no-repeat;
	background-size: 100%;
}
/* 平台月度经营分析 */
.ptydjyfx {
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Ftabbar%2Fydjyfx.png") no-repeat;
	background-size: 100%;
}
/* 平台用户数据 */
.ptyhsj {
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Ftabbar%2Fptyhsj.png") no-repeat;
	background-size: 100%;
}
/* 经营预警 */
.jyyj {
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Ftabbar%2Fjjyj.png") no-repeat;
	background-size: 100%;
}
.container .swiper .item:nth-child(5) {
	transform: translateY(10px) skewY(-4deg);  
}
.ydjyfx {
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Ftabbar/ydjyfx.png") no-repeat;
	background-size: 100%;
}
.container .swiper .left {
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Fleft.png") no-repeat;
	background-size: 100%; 
}
.container .swiper .right {
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Fright.png") no-repeat;
	background-size: 100%; 
}
.container .swiper .item .subTitle {
	text-align: center;
	height: 50px;
	line-height: 70px; 
	color: #FF9B03;
	font-size: 16px;
}
 
