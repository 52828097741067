 .container {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: black url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Flogin%2Flogin.png") no-repeat;
	background-size: 100%;
} 

.container .login {
	width: 350px;
	height: 360px;
	margin-top: 180px; 
	display: flex;
	flex-direction: column;
	justify-content: space-evenly; 
}

.container .login .submit {
	display: block;
	width: 342px;
	height: 62px;
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Flogin%2FloginBtn.png") no-repeat;
	background-size: 100%;
} 

.container .login .ipt { 
	height: 62px;
	overflow: hidden;
}

.container .login .remember { 
	height: 30px; 
	width: 80px;
	display: flex;
	justify-content: space-between; 
	cursor: pointer;
}

.container .login .remember .icon {
	width: 16px;
	height: 16px;
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Flogin%2Fselected.png") no-repeat;
	background-size: 100%;
}

.container .login .remember .un {
	width: 16px;
	height: 16px;
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Flogin%2Fun.png") no-repeat;
	background-size: 100%;
} 

.container .login .remember .rememberText {  
	line-height: 15px;
	color: #6086D8;
	font-size: 14px;
}

.container .login .username {
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Flogin%2Fusername.png") no-repeat;
	background-size: 100%;
}

.container .login .pwd {
	background: url("https://jxhl-shop-1311461544.cos.ap-nanjing.myqcloud.com/cockpit%2Flogin%2Fpassword.png") no-repeat;
	background-size: 100%;
}

.usr,.password {
	float: right;
	width: 77%;
	height: 55px;
	background-clip: content-box;
	background: transparent;
	color: white;  
	font-size: 18px;
}

input {
	border: none;
	margin: 0;
	padding: 5px;
	outline: none;
}
input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color : #30447B;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color : #30447B;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color : #30447B;
}
input:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color : #30447B;
}
input::-ms-input-placeholder { /* Microsoft Edge */
   color : #30447B;
}